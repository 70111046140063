.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin-top: -50px;

    h1 {
        display: block;
        // align-items: ;
        font-size: 46px;
        line-height: 38px;
        text-transform: uppercase;
        font-family: "Venera";
        text-align: center;
        padding: 5vh 0 40px;
        text-align: left;
        letter-spacing: 1px;

        .title {
            font-family: "Venera 300";
            letter-spacing: 2px;
            padding-bottom: 4px;
        }
    }
}

.grid {
    display: grid;
    // grid-gap: 40px;
    grid-template-rows: 126px;
    position: relative;
    z-index: 100;
    width: 75vw;
    aspect-ratio: 1640 / 855;
    padding-top: 2vh;

    .row {
        display: flex;
        gap: 5vw;

        .session {
            position: relative;
            display: flex;
            align-items: center;
            gap: 5vw;
            border-top: 2px solid red;
            border-bottom: 2px solid red;
            height: fit-content;
            padding: 1vh 2vw;
            width: 100%;

            .date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                   
                .dateDay, .dateMonth {
                    font-family: "Bull Text";
                    display: block;
                    color: #a7aaac;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: 2.5px;
                    text-align: left;
                    width: 54px;
                }

                .dateDay{
                    font-size: 16px;
                    display: flex;
                }

                .dateMonth {
                    font-size: 13px;
                    letter-spacing: 5px;
                }

                .dateSuffix {
                    font-size: 8px;
                    letter-spacing: 1px;
                    padding-top: 2px;
                }
            }

           
            &.isRace {
                width: 48%;
                background-size: contain;
                background-repeat: no-repeat;
                border: none;
            }

            span {
                position: relative;
                display: block;
                z-index: 2;

                font-family: "Venera";
                font-weight: 900;
                text-transform: uppercase;
                font-size: 25px;
                // line-height: 23px;
                // color: #fcd700;
                letter-spacing: -1px;
            }

            .time {
                font-family: "Bull Text";
                font-weight: 900;
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 5.5px;
                color: #a7aaac;
            }

            &.isPast {
                border-top: 2px solid #a7aaac;
                border-bottom: 2px solid #a7aaac;

                span {
                    color: #a7aaac
                }
            }

        }
    }
}
