// .container {
//     position: relative;
//     height: 100vh;
//     width: 100vw;
//     overflow: hidden;

//     img {
//         position: absolute;
//         object-fit: cover;
//         object-position: center;
//         pointer-events: none;
//         user-select: none;
//         // z-index:;

//         &:nth-child(1)  {
//             height: 95%;
//             top: 2.5%;
//             left: 0;
//             right: 0;
//             margin: auto;
//         }

//         &:nth-child(2) {
//             width: 95%;
//             left: 2.5%;
//             top: 0;
//             bottom: 0;
//             margin: auto;
//         }
//     }

//     .cards {
//         position: relative;
//         z-index: 1;
//         display: grid;
//         grid-template-columns: repeat(2, 1fr);
//         grid-row-gap: 4vh;
//         grid-column-gap: 2vw;
        
        
//         .card {
//             height: 48vh;
//             width: 49vw;
//             background-size: cover;
//             background-position: center;
//             cursor: pointer;
//             border: 0.5px solid #000;

//             &.isDisabled {
//                 pointer-events: none;
//                 opacity: 0.25;
//             }
//         }
//     }
// }
.container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        user-select: none;
        // z-index:;

        &:nth-child(1) {
            height: 95%;
            top: 2.5%;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:nth-child(2) {
            width: 95%;
            left: 2.5%;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .cards {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 3.5vh;
        grid-column-gap: 3vw;
        height: 100vh;
        width: 100vw;
        padding: 0;
        
        .card {
            height: 48.3vh;
            width: 48.5vw;
            background-size: cover;
            background-position: center;
            cursor: pointer;
            position: relative;
            
            // Remove general border
            border: none;
            
            // Add light glow effect to make borders more visible
            box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
            transition: all 0.3s ease;
            
            &:hover {
                box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
            }

            &.isDisabled {
                pointer-events: none;
                opacity: 0.25;
            }
            
            // Top-left card (Race Hub)
            &:nth-child(1) {
                border-right: 0.5px solid rgba(255, 255, 255, 0.7);
                border-bottom: 0.5px solid rgba(255, 255, 255, 0.7);
            }
            
            // Top-right card (Pit Crew)
            &:nth-child(2) {
                border-left: 0.5px solid rgba(255, 255, 255, 0.7);
                border-bottom: 0.5px solid rgba(255, 255, 255, 0.7);
            }
            
            // Bottom-left card (Race Support)
            &:nth-child(3) {
                border-right: 0.5px solid rgba(255, 255, 255, 0.7);
                border-top: 0.5px solid rgba(255, 255, 255, 0.7);
            }
            
            // Bottom-right card (RB21)
            &:nth-child(4) {
                border-left: 0.5px solid rgba(255, 255, 255, 0.7);
                border-top: 0.5px solid rgba(255, 255, 255, 0.7);
            }
        }
    }
}