.location {
    position: absolute;
    transform-origin: top left;
    transform: rotate(-90deg);
    left: 35px;
    bottom: 0;

    p {
        font-family: "Share Tech Mono";
        text-transform: uppercase;
        color: #fcd700;

        &:nth-child(1) {
            font-size: 34px;
            margin-bottom: 5px;
        }

        &:nth-child(2) {
            font-size: 19px;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 50px;
        // line-height: 84px;
        text-transform: uppercase;
        font-family: "Venera";
        font-weight: 900;
        -webkit-text-stroke: 1px;
        text-stroke: 1px;
        text-align: center;
    }

    h3 {
        font-family: "Venera 300";
        font-weight: 300;
        text-align: center;
        font-size: 20px;
    }

    .row {
        display: flex;
        justify-content: center;
        width: 100vw;
        margin-top: 10px;
        height: 50vh;
    }

    .track {
        width: 40vw;
        margin-right: 1vw;
        align-self: center;
        
        img {
            position: relative;
            max-height: 54vh;
            margin: auto;
        }
    }

    .stats {
        width: 45vw;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;    
        align-content: center;
        display: flex;

        .stats {
            width: 50%;
            display: block;  
            margin-left: 3vw;   
            align-self: center;   

            .stat {
                padding: 3px 0;
                P {
                    display: block;
                    margin-top: 5px;
                    font-family: "Venera 300";
                    font-weight: 300; 
                    font-size: 14px;
                }
    
                span {
                    display: block;
                    margin-top: 1px;
                    font-family: "Venera";
                    font-weight: 900;
                    font-size: 14px;
                }
            }            
        }

        .trackInfo{
            width: 40%;
            align-self: center;
            justify-self: center; 
            position: relative;
            
            .trackInfoContent {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
               
                .downforce, .deg {
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    margin-left: -7px;
                    font-family: 'Venera 300';
                    font-size: 6.5px;
                }
    
                .downforce {
                    top: -5px;
                }

                .deg {
                    bottom: -9px;
                }
            }
        }

       
       
    }
}
