@import "./assets/styles/reset.scss";

@font-face {
    font-family: "Venera";
    src: url("./assets/fonts/Venera900.woff2") format("woff2"),
        url("./assets/fonts/Venera900.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Venera 300";
    src: url("./assets/fonts/Venera-300.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Share Tech Mono";
    src: url("./assets/fonts/ShareTechMono-Regular.woff2") format("woff2"),
        url("./assets/fonts/ShareTechMono-Regular.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Bull Text";
    src: url("./assets/fonts/BullText-Regular.woff2") format("woff2"),
        url("./assets/fonts/BullText-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body {
    margin: 0;
    color: #fff;
    background: #000f1e;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 0;
}

.lang_chooser {
    position: fixed;
    z-index: 99999;
    bottom: 18px;
    right: 18px;
    width: 225px;

    cosmos-flag {
        margin-bottom: -1.5px;
        margin-right: 6px;
    }
}
