.wrapper {
    z-index: 1;
    overflow: hidden;
    height: fit-content;
    width: 100%;

    .burger {
        position: absolute;
        z-index: 230599;
        height: 70px;
        width: 99px;
        right: 1vw;
        top: 6vh;
        cursor: pointer;
        user-select: none;
        background-size: 45px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .background {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 797px) {
            background-size: 400%;
        }
    }

    .trim {
        width: 95%;
        height: 95%;
        position: absolute;
        top: 2.5%;
        z-index: 2;
        left: 2.5%;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 15;
        touch-action: none;
        user-select: none;
        pointer-events: none;
    }

    .centralNumber {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        mix-blend-mode: overlay;
        opacity: 0.4;
        font-size: clamp(295px, 30vw, 675px);
        -webkit-text-stroke: 7px #fff;
        text-stroke: 7px #fff;

        @media (max-width: 1200px) {
            top: 35%;
        }

        @media (max-width: 1200px) {
            top: 24%;
        }
    }

    .gradTopBlue {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, rgba(0, 26, 48, 0) 72.5%, rgba(0, 26, 48, 1) 100%);

        @media (max-width: 797px) {
            background: linear-gradient(
                0deg,
                rgba(2, 27, 48, 1) 0%,
                rgba(0, 26, 48, 0) 45%,
                rgba(0, 26, 48, 0) 90%,
                rgba(0, 26, 48, 1) 100%
            );
            height: 100vh;
        }
    }

    
    .bgTop {
        background-size: cover;
    }

    .bgHero {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 44vw;
        aspect-ratio: 670/1463;
        background-size: cover;
        min-height: 100vh;

        @media (max-width: 797px) {
            height: 100%;
            min-height: unset;
            width: 100vw;
            mask-image: linear-gradient(to bottom, black 34%, transparent 100%);
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 26%;
            background: linear-gradient(90deg, rgba(2, 27, 48, 0) 0%, rgba(0, 26, 48, 1) 100%);

            @media (max-width: 797px) {
                content: unset;
            }
        }

        &::part(image) {
            object-position: top center;
        }
    }

    .namePosition {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 10px;
        pointer-events: none;
        transition: opacity 300ms;

        @media (max-width: 797px) {
            left: unset;
            right: 0;
        }

        &.isLoading {
            opacity: 0;
        }

        .name {
            position: absolute;
            left: -100%;
            transform-origin: bottom left;
            transform: rotate(90deg);

            @media (max-width: 797px) {
                left: unset;
                right: 100%;
                transform-origin: bottom right;
                transform: rotate(270deg);
            }

            .nameInner {
                transform: rotate(180deg);
                display: flex;
                align-items: center;
            }

            p {
                font-size: 100px;
                line-height: 100px;
                font-weight: 800;
                text-transform: uppercase;
                color: transparent;
                -webkit-text-stroke: 1.5px #fff;
                opacity: 0.3;
                letter-spacing: -0.4px;

                &:nth-last-child(2) {
                    opacity: 1;
                    color: #fff;
                }

                @media (max-width: 797px) {
                    font-size: 60px;
                    line-height: 75px;
                    -webkit-text-stroke: 1px #fff;

                    &:nth-last-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        opacity: 1;
                        color: #fff;
                    }
                }
            }
        }
    }
}
