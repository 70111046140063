.close {
    position: absolute;
    z-index: 230599;
    height: 66px;
    width: 81px;
    right: 1vw;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-size: 72px;
    background-position: center;
    background-repeat: no-repeat;
    top: 3.5vh;
}

.container {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    .reset {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .touchpoint {
        position: absolute;
        z-index: 21;
        max-width: 200px;
        cursor: pointer;
        transition: 300ms;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }

        .title {
            position: relative;
            z-index: 21;
            font-size: 14px;
            line-height: 20px;
            color: #fff;
            font-family: "Venera";
            font-weight: 900;

            img {
                display: inline-block;
                height: 20px;
            }
        }

        .content {
            position: absolute;
            z-index: 22;
            // background: #2a4c97;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            bottom: -5px;
            left: -5px;
            padding: 20px 22px;
            width: 275px;
            transition: 250ms;
            opacity: 0;
            pointer-events: none;

            &.isShowing {
                opacity: 1;
            }

            .closeTouchpoint {
                position: absolute;
                top: -9px;
                right: -10px;
                height: 22px;
            }

            p {
                font-family: "Bull Text";
                font-size: 14px;
                line-height: 18px;
                text-transform: unset;

                &:first-of-type {
                    font-family: "Venera";
                    font-size: 17px;
                    line-height: 22px;;
                    font-weight: 900;
                    max-width: 200px;
                    margin-bottom: 12px;
                }
            }
        }

        .arrow {
            display: block;
            position: relative;
            position: absolute;
        }

        &.frontWing {
            left: 5vw;
            bottom: 13.5vh;

            .content {
                left: 28vw;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 70px;
                top: -80px;
                left: 62px;
            }
        }

        &.frontSuspension {
            left: 31vw;
            bottom: 17vh;

            .content {
                left: -14vw;
                right: unset;
                bottom: 40vh;
                width: 450px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 144px;
                top: -151px;
                left: -9px;
            }
        }

        &.halo {
            left: 39.5vw;
            top: 31vh;

            .content {
                left: 12vw;
                right: unset;
                bottom: -150px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 8px;
            }

            .arrow {
                height: 50px;
                bottom: -55px;
                right: 10px;
            }
        }

        &.floor {
            left: 59vw;
            bottom: 30vh;

            .content {
                left: 8vw;
                right: unset;
                bottom: 57px;
            }

            .open {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -23px;
            }

            .arrow {
                height: 50px;
                top: -60px;
                left: 15px;
            }
        }

        &.coolingSidePods {
            left: 58vw;
            top: 24vh;

            .title {
                max-width: 120px;
            }

            .content {
                left: -10vw;
                right: unset;
                bottom: -480px;
                width: 400px;
            }

            .open {
                position: absolute;
                margin-left: 10px;
                bottom: 2px;
            }

            .arrow {
                height: 100px;
                bottom: -110px;
                left: 89px;
            }
        }

        &.rearWing {
            bottom: 74.5vh;
            left: 80vw;

            .content {
                left: unset;
                right: 35px;
                bottom: -480px;
                width: 340px;
            }

            .open {
                margin-bottom: -6px;
                margin-left: 14px;
            }

            .arrow {
                height: 60px;
                bottom: -65px;
                left: -19px;
            }
        }

        &.rearSuspension {
            right: 12vw;
            bottom: 36vh;

            .title {
                max-width: 120px;
            }

            .content {
                right: unset;
                left: -55vw;
                bottom: -25vh;
                width: 475px;
            }

            .open {
                position: absolute;
                margin-left: 117%;
                bottom: 2px;
            }

            .arrow {
                height: 102px;
                bottom: 44px;
                right: 90%;
            }
        }
    }
}
