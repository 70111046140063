// .close {
//     position: absolute;
//     z-index: 230599;
//     height: 90px;
//     width: 135px;
//     right: 10px;
//     cursor: pointer;
//     user-select: none;
//     background-size: 72px;
//     background-position: center;
//     background-repeat: no-repeat;
// }

.container {
   width: 100vw;
   height: 100vh;
   position: absolute;
   z-index: 1;
   top: 0;

    .operations {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 95vw;
    }
}
