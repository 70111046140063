.container {
    display: flex;
    justify-content: center;
    width: 90vw;
    margin: 0 auto;
    column-gap: 3vw;
    z-index: 5;
    position: absolute;

    .constructors, .drivers {
        width: 45vw;
        height: 90%;
        display: flex;
        flex-direction: column;
    }

    .header {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #294689; 
        color: white;
        padding: 10px 20px;
        width: 100%;
        
        // Create the angled corner with clip-path
        clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);

        &.constructor {
            // make the clip path at the start of the div
            clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
        }
        
        h1 {
          font-size: 15px;
          text-align: center;
          font-family: 'Venera';
        }
    }

    .table {
        width: 100%;
        max-height: 70vh;
        display: flex;
        flex-direction: column;

        .contents {
            background: rgb(27,56,101);
            background: linear-gradient(45deg, rgba(27,56,101,0.5) 0%, rgba(18,43,77,0.5) 45.392%, rgba(6,27,47,0.5) 100%);
            border: 1px solid #294689;
            padding-top: 20px;
            overflow-y: auto;
            padding-bottom: 40px;
            -webkit-mask-image: linear-gradient(to top, transparent 0%, black 15%);
            mask-image: linear-gradient(to top, transparent 0%, black 15%);
            flex: 1;
        }

        .row {
            border-radius: 4px;
            margin: 2px 0;
            padding: 2px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Bull Text';
            font-size: 8px;
            
            .border {
                display: grid;
                grid-template-columns: 70% 25%;
                border: 1px solid #294689;
                width: 60%;
                padding: 8px 0 8px 5px;
                justify-content: end;
                align-content: center;
                align-items: center;
            }

            &.driverTable {
                width: 80%;
                justify-content: left;
                margin-left: 5px;
                
                .border {
                    grid-template-columns: 55% 40%;
                }
            }

            .rbrLogo {
                width: 40px;
            }
            
            .points {
                font-family: 'Bull Text';
                color: white;
                font-size: 10px;
                padding-left: 10px;
            }

            &.highlight {
                color: red;
                font-family: 'Venera';
                letter-spacing: -3px;
                font-weight: 900;

                .border { 
                    border:none;
                    background: rgb(42,77,155);
                    background: linear-gradient(-90deg, rgba(42,77,155,1) 0%, rgba(18,43,77,1) 50%, rgba(6,27,47,1) 91.654%);
                    clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);
                    height: 4vh;
                }
                font-size: 20px;

                .points {
                    font-size: 20px !important;
                    letter-spacing: 0;
                }

                .position {
                    color: white !important;
                }
                
            }
        
            .position {
                width: 10%;
                justify-items: center;
                font-family: 'Bull Text';
                text-align: center;
            }
        }

        .car {
            margin-top: -8vh;
        }
    }

    .driversImage {
        position: absolute;
        bottom: 0;
        width: 33%;
        height: auto;
        right: -53px;
        pointer-events: none;
        touch-action: none;
        // z-index: 5;
    }
}