.slider {
    position: relative;
    z-index: 10;
    width: 100vw;
    overflow-x: auto;
    display: grid;
    scroll-snap-type: x mandatory;
    grid-template-columns: 1fr 1fr 1fr;

    .slide {
        width: 100vw;
        height: 100vh;
        cursor: grab;
        scroll-snap-align: start;
        scroll-snap-stop: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}

.round {
    position: absolute;
    z-index: 10;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: overlay;
    font-size: 120vh;
    line-height: 1em;
    opacity: 0.4;
    text-stroke: 6px;
    -webkit-text-stroke: 6px;
    font-family: "Share Tech Mono";
    transition: 500ms opacity;

    &.fadeOut {
        opacity: 0;
    }
}

.schedule {
    position: absolute;
    top: calc(61% - 33px);
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.22;
    z-index: 11;
    width: 75vw;
    aspect-ratio: 1640/855;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    pointer-events: none;
    transition: 500ms;

    &.isHidden {
        opacity: 0;
        left: 45%;
    }
}

.swipe {
    position: absolute;
    z-index: 11;
    top: 45vh;
    // bottom: 0;
    right: 5vw;
    transition: 500ms opacity;
    pointer-events: none;
    width: 2vw;
    align-self: center;

    &.fadeOut {
        opacity: 0;
    }
}
